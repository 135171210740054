var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-popconfirm',{attrs:{"title":"Réinitialiser les livraisons ?"},on:{"confirm":function($event){return _vm.order.resetDeliveries()}}},[_c('el-button',{attrs:{"slot":"reference","type":"danger","small":""},slot:"reference"},[_vm._v("Reset")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.deliveriesLoading),expression:"deliveriesLoading"}],key:_vm.tableKey,staticStyle:{"width":"100%"},attrs:{"data":_vm.deliveries,"fit":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"Nb. Codes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-progress',{attrs:{"text-inside":true,"stroke-width":20,"percentage":100 - (100 * (row.distributedCodesNumber / row.totalCodesNumber))}}),_c('span',[_vm._v(_vm._s(((row.totalCodesNumber) + " code(s) restant")))])]}}])}),_c('el-table-column',{attrs:{"label":"Montant","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.$n(row.amount, 'currency')))])]}}])}),_c('el-table-column',{attrs:{"label":"Created At","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"datetime","placeholder":"Date de création"},on:{"change":function($event){return _vm.save(row)}},model:{value:(row.createdAt),callback:function ($$v) {_vm.$set(row, "createdAt", $$v)},expression:"row.createdAt"}})]}}])}),_c('el-table-column',{attrs:{"label":"Activate At","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(row.activateAt,'DD/MM/YYYY')))])]}}])}),_c('el-table-column',{attrs:{"label":"Expire At","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(row.expireAt,'DD/MM/YYYY')))])]}}])}),_c('el-table-column',{attrs:{"label":"Match / Processed","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.match)+" / "+_vm._s(row.processed))]),(!row.processed)?_c('el-popconfirm',{attrs:{"title":"Générer les cartes à partir du fichier ?","confirm-button-text":"Oui","cancel-button-text":"Non"},on:{"confirm":function($event){return _vm.order.createCards()}}},[_c('i',{staticClass:"el-icon-refresh",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"110px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Voir la livraison","placement":"top"}},[_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-view"},on:{"click":function($event){return _vm.openCardsManager(row)}}})],1),_c('el-popconfirm',{attrs:{"confirm-button-text":"Oui","cancel-button-text":"Non","icon":"el-icon-info","icon-color":"red","title":"Êtes-vous sûr ?"},on:{"confirm":function($event){return _vm.handleDelete(row, $index)}}},[_c('el-button',{attrs:{"slot":"reference","size":"mini","type":"danger","icon":"el-icon-delete"},slot:"reference"})],1)]}}])})],1),(_vm.deliverySelected)?_c('cards-manager-drawer',{attrs:{"isBulk":_vm.order.isBulk,"delivery":_vm.deliverySelected,"visible":_vm.cardsManagerDrawerVisible},on:{"close":function($event){_vm.cardsManagerDrawerVisible = false},"done":_vm.handleCloseCardsManager}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }