










































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Order, BalanceMove } from '@/models/index'

@Component({
  name: 'BalanceMovesTable'
})
export default class extends Vue {
  @Prop({ required: true }) private order!: Order

  private balanceMoves: BalanceMove[] = []
  private tableKey = 0
  private orderBalancesLoading = false

  created() {
    this.getBalanceMoves()
  }

  private async getBalanceMoves() {
    this.orderBalancesLoading = true
    const { data } = await BalanceMove
      .where({ sourceId: this.order.id })
      .where({ sourceType: 'Order' })
      .all()

    this.balanceMoves = data
    this.orderBalancesLoading = false
  }

  private async handleDelete(data: BalanceMove, index: number) {
    await data.destroy()

    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })

    this.balanceMoves.splice(index, 1)
  }
}
