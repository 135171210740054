





























































































































































import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { Order, GiftCard, Buyer, Invoice, CardDistribution } from '@/models/index'
import { ITagView, TagsViewModule } from '@/store/modules/tags-view'

@Component({
  name: 'OrderEdit',
  components: {
    InvoicesTable: () => import('./components/invoices-table.vue'),
    DeliveriesTable: () => import('./components/deliveries-table.vue'),
    OrderMovesTable: () => import('./components/balance-moves-table.vue'),
    CardDistributionTooltip: () => import('@/components/CardDistributionTooltip/index.vue'),
    CardDistributionEditDrawer: () => import('@/components/CardDistributionEditDrawer/index.vue')
  }
})
export default class extends Vue {
  private order: Order = new Order({
    giftCard: { id: '' },
    buyer: { id: '' },
    invoice: { id: '' }
  })

  private giftCardList: GiftCard[] = []
  private buyerList: Buyer[] = []
  private invoiceList: Invoice[] = []

  private orderLoading = true
  private cardDistributionEditDrawerVisible = false

  private deliveryModeOptions = [
    {
      name: 'File',
      value: 'file_delivery'
    },
    {
      name: 'Api',
      value: 'api_delivery'
    },
    {
      name: 'Physical',
      value: 'physical_retrieval'
    }
  ]

  private retrievalModeOptions = [
    {
      name: 'File',
      value: 'file_retrieval'
    },
    {
      name: 'Api',
      value: 'api_retrieval'
    },
    {
      name: 'Physical',
      value: 'physical_retrieval'
    }
  ]

  private rules = {
    giftCard: [{
      required: true,
      message: 'giftCard is required',
      trigger: 'blur'
    }],
    invoice: [{
      required: true,
      message: 'Invoice is required',
      trigger: 'blur'
    }]
  }

  created() {
    if (!this.isCreate) this.getOrder()
  }

  get orderDetails() {
    return [
      {
        label: 'Valeur faciale',
        value: `${this.$n(this.order.amount, 'currency') || '- €'}`
      },
      {
        label: 'Commission TTC',
        value: `${this.order.discount?.toFixed(2) || '-'} %`
      },
      {
        label: 'Commission HT',
        value: `${this.order.discountWithoutVat?.toFixed(2) || '-'} %`
      },
      {
        label: 'Montant remisé TTC',
        value: `${this.$n(this.order.discountedAmount, 'currency') || '- €'}`
      },
      {
        label: 'Montant remisé HT',
        value: `${this.$n(this.order.discountedAmountWithoutVat, 'currency') || '- €'}`
      },
      {
        label: 'Credit (Prepaid account)',
        value: `${this.$n(this.order.balanceEarned, 'currency') || '- €'}`
      },
      {
        label: 'Debit (Prepaid account)',
        value: `${this.$n(this.order.balanceBurned, 'currency') || '- €'}`
      },
      {
        label: 'Montant à payer',
        value: `${this.$n(this.order.payableAmount, 'currency') || '- €'}`
      }
    ]
  }

  get isCreate() {
    return this.$route.name === 'OrderCreate'
  }

  get loading() {
    return this.orderLoading
  }

  mounted() {
    this.updateTagView()
  }

  private refresh() {
    this.getOrder()
  }

  private async getOrder() {
    this.orderLoading = true
    const { data } = await Order
      .includes([{ giftCard: 'skus' }, 'buyer', 'wholesaler', 'invoices', { cardDistributions: 'sku' }])
      .find(this.$route.params.id)

    this.order = data
    this.updateTagView(data)
    this.orderLoading = false
  }

  updateTagView(order?: Order) {
    const matchTagView: any = TagsViewModule.visitedViews.filter(view => view.params?.id === this.$route.params.id)

    if (matchTagView.length === 1) {
      matchTagView[0].title = `Order ${order?.number || 'loading...'}`
      matchTagView[0].meta.title = `Order ${order?.number || 'loading...'}`
      TagsViewModule.updateVisitedView({ ...matchTagView[0] as ITagView })
    }
  }

  private async update() {
    (this.$refs.dataForm as Form).validate(async(valid: any) => {
      if (valid) {
        const data = this.order

        await data.save({ with: { cardDistributions: 'sku.id' } })

        this.$notify({
          title: 'Order updated',
          message: 'Order successfully updated',
          type: 'success',
          duration: 2000
        })
      }
    })
  }

  private addCardDistribution() {
    const sku = this.order.giftCard.skus[0].dup()
    this.order.cardDistributions.push(new CardDistribution({ quantity: 1, amount: 50, sku: sku }))
  }

  private handleSkuSelect(skuId: string, cdIndex: number) {
    const selected = this.order.giftCard.skus.find(e => e.id === skuId)
    if (selected) {
      this.order.cardDistributions[cdIndex].sku = selected.dup()
    }
  }

  private async deleteCardDistribution(cd: CardDistribution, index: number) {
    if (cd.isPersisted) {
      await cd.destroy()

      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })
    }
    this.order.cardDistributions.splice(index, 1)
  }
}
