




















import { Component, Vue } from 'vue-property-decorator'
import { Buyer, GiftCard, Wholesaler } from '@/models'

@Component({
  name: 'Orders',
  components: {
    OrdersTable: () => import('./orders-table/index.vue'),
    OrdersBulkTable: () => import('./orders-bulk-table/index.vue')
  }
})
export default class extends Vue {
  private giftCards: any[] = []
  private giftCardsLoading = false

  private buyers: any[] = []
  private buyersLoading = false

  private wholesalers: any[] = []
  private wholesalersLoading = false

  created() {
    this.getGiftCards()
    this.getBuyers()
    this.getWholesalers()
  }

  private async getGiftCards() {
    this.giftCardsLoading = true
    const { data } = await GiftCard.per(500).page(1).order('name').all()

    const tempList: any = {}
    data.forEach(e => {
      const firstChar = e.name.charAt(0)
      if (!tempList[firstChar]) tempList[firstChar] = { label: firstChar, options: [e] }
      else tempList[firstChar].options.push(e)
    })

    this.giftCards = Object.values(tempList)
    this.giftCardsLoading = false
  }

  private async getBuyers() {
    this.buyersLoading = true
    const { data } = await Buyer.per(999).page(1).order('name').all()

    const tempList: any = {}
    data.forEach(e => {
      const firstChar = e.name.charAt(0)
      if (!tempList[firstChar]) tempList[firstChar] = { label: firstChar, options: [e] }
      else tempList[firstChar].options.push(e)
    })

    this.buyers = Object.values(tempList)
    this.buyersLoading = false
  }

  private async getWholesalers() {
    this.wholesalersLoading = true
    const { data } = await Wholesaler.per(999).page(1).order('name').all()

    const tempList: any = {}
    data.forEach(e => {
      const firstChar = e.name.charAt(0)
      if (!tempList[firstChar]) tempList[firstChar] = { label: firstChar, options: [e] }
      else tempList[firstChar].options.push(e)
    })

    this.wholesalers = Object.values(tempList)
    this.wholesalersLoading = false
  }
}
