
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Invoice } from '@/models/index'

@Component({
  name: 'InvoicesTable'
})
export default class extends Vue {
  @Prop({ required: true }) private invoices!: Invoice
}
