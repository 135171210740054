



















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Delivery, Order } from '@/models/index'
import DirectUpload from '@/components/DirectUpload/index.vue'

@Component({
  name: 'DeliveriesTable',
  components: {
    DirectUpload,
    CardsManagerDrawer: () => import('@/components/CardsManagerDrawer/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private order!: Order

  private deliveries: Delivery[] = []
  private deliverySelected: Delivery | null = null

  private deliveriesLoading = false

  private cardsManagerDrawerVisible = false

  private distributionAmount = 0
  private distributionQuantity = 0

  private tempData = new Delivery({ order: this.order, distribution: {} })

  private rules = {
    amount: [{ required: true, message: 'amount is required', trigger: 'blur' }],
    requirement: [{ required: true, message: 'requirement is required', trigger: 'blur' }]
  }

  private tableKey = 0
  private dialogFormVisible = false
  private dialogStatus = ''
  private textMap = {
    update: 'Edit',
    create: 'Create'
  }

  private createOrUpdateLoading = false

  created() {
    this.getDeliveries()
  }

  private async getDeliveries() {
    this.deliveriesLoading = true
    const { data } = await Delivery.where({ orderId: this.order.id }).all()

    this.deliveries = data
    this.deliveriesLoading = false
  }

  private resetTempData() {
    this.tempData = new Delivery({ order: this.order, distribution: {} })
  }

  private openCardsManager(delivery: Delivery) {
    this.deliverySelected = delivery.dup()
    this.cardsManagerDrawerVisible = true
  }

  private handleCloseCardsManager() {
    this.cardsManagerDrawerVisible = false
  }

  private uploadCallback(file: any) {
    this.tempData.codesFile = file.blob.signed_id
  }

  private addDistribution() {
    this.$set(this.tempData.distribution, this.distributionAmount, this.distributionQuantity)
  }

  private deleteDistribution(key: string) {
    delete this.tempData.distribution[key]
    this.tempData.distribution = Object.assign({}, this.tempData.distribution)
  }

  private async save(delivery: Delivery) {
    await delivery.save()

    this.$notify({
      title: 'Delivery updated',
      message: 'Delivery successfully updated',
      type: 'success',
      duration: 2000
    })
  }

  private async handleDelete(data: Delivery, index: number) {
    await data.destroy()

    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })

    this.deliveries.splice(index, 1)
  }
}
