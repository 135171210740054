



























































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { Order } from '@/models/index'

@Component({
  name: 'OrdersBulkTable',
  components: {
    Pagination,
    FiltersDrawer: () => import('../components/FiltersDrawer.vue'),
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue'),
    CardDistributionTooltip: () => import('@/components/CardDistributionTooltip/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private giftCards!: any[]
  @Prop({ required: true }) private giftCardsLoading!: boolean

  @Prop({ required: true }) private wholesalers!: any[]
  @Prop({ required: true }) private wholesalersLoading!: boolean

  private tableOrderBulkKey = 1
  private listOrderBulk: Order[] = []
  private totalOrderBulk = 0
  private loadingOrderBulk = true
  private listQueryBulk = {
    page: 1,
    limit: 20,
    number: undefined,
    status: undefined,
    giftCardId: '',
    wholesalerId: '',
    paymentStatus: undefined,
    deliveryStatus: undefined,
    dateRange: null
  }

  private paymentStatusOptions = [
    {
      value: 'unpaid',
      label: 'Non payée'
    },
    {
      value: 'paid',
      label: 'Payée'
    },
    {
      value: 'late',
      label: 'En retard'
    }
  ]

  private deliveryStatusOptions = [
    {
      value: 'undelivered',
      label: 'Non livrée'
    },
    {
      value: 'in_delivery',
      label: 'En livraison'
    },
    {
      value: 'delivered',
      label: 'Livrée'
    }
  ]

  created() {
    this.getListOrderBulk()
  }

  private async getListOrderBulk() {
    this.loadingOrderBulk = true
    const createdAtGte = this.listQueryBulk.dateRange ? this.$moment(String(this.listQueryBulk.dateRange![0])).startOf('day').format('YYYY-MM-DD HH:mm:ss') : ''
    const createdAtLte = this.listQueryBulk.dateRange ? this.$moment(String(this.listQueryBulk.dateRange![1])).endOf('day').format('YYYY-MM-DD HH:mm:ss') : ''

    const { data, meta } =
        await Order
          .where({
            number: { prefix: this.listQueryBulk.number },
            status: this.listQueryBulk.status,
            giftCardId: this.listQueryBulk.giftCardId,
            wholesalerId: this.listQueryBulk.wholesalerId,
            paymentStatus: this.listQueryBulk.paymentStatus,
            deliveryStatus: this.listQueryBulk.deliveryStatus,
            createdAt: { gte: createdAtGte, lte: createdAtLte },
            bulk: true
          })
          .includes(['buyer', 'giftCard', 'seller', 'wholesaler', 'invoices', 'cardDistributions'])
          .order({ createdAt: 'desc' })
          .page(this.listQueryBulk.page)
          .per(this.listQueryBulk.limit)
          .stats({ total: 'count' })
          .all()

    this.listOrderBulk = data
    this.totalOrderBulk = meta.stats.total.count
    this.loadingOrderBulk = false
  }

  private handleFilterBulk() {
    this.listQueryBulk.page = 1
    this.getListOrderBulk()
  }

  private handleGiftCardSelect(giftCardId: string) {
    this.listQueryBulk.page = 1
    this.listQueryBulk.giftCardId = giftCardId
    this.getListOrderBulk()
  }

  private async handleDelete(data: Order, index: number) {
    await data.destroy()

    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })
    this.listOrderBulk.splice(index, 1)
    this.totalOrderBulk -= 1
  }

  private handleAction(command: any, order: Order) {
    if (command === 'edit') this.$router.push({name: 'OrderEdit', params: {id: order.id}})
    else if (command == 'resume') order.resume()
    else if (command == 'suspend') order.suspend()
  }

  private handleWholesalerSelect(wholesalerId: string) {
    this.listQueryBulk.page = 1
    this.listQueryBulk.wholesalerId = wholesalerId
    this.getListOrderBulk()
  }

  private applyFilters(filters: any) {
    this.listQueryBulk.status = filters.status
    this.listQueryBulk.dateRange = filters.dateRange
    this.listQueryBulk.paymentStatus = filters.paymentStatus
    this.listQueryBulk.deliveryStatus = filters.deliveryStatus
    this.listQueryBulk.page = 1
    this.getListOrderBulk()
  }
}
