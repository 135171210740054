
















































































import { Component, Vue } from 'vue-property-decorator'
import { GiftCard } from '@/models'

@Component({
  name: 'FiltersDrawer'
})
export default class extends Vue {
  private showFilters = false
  private search = ''
  private filters: any = {
    dateRange: null,
    giftCardId: '',
    status: undefined,
    paymentStatus: undefined,
    deliveryStatus: undefined,
    page: 1,
    limit: 20
  }

  private statusOptions = [
    {
      value: 'waiting',
      label: 'En attente'
    },
    {
      value: 'processing',
      label: 'En traitement'
    },
    {
      value: 'fulfilled',
      label: 'Honorée'
    },
    {
      value: 'suspended',
      label: 'En suspens'
    }
  ]

  private paymentStatusOptions = [
    {
      value: 'unpaid',
      label: 'Non payée'
    },
    {
      value: 'paid',
      label: 'Payée'
    },
    {
      value: 'late',
      label: 'En retard'
    }
  ]

  private deliveryStatusOptions = [
    {
      value: 'undelivered',
      label: 'Non livrée'
    },
    {
      value: 'in_delivery',
      label: 'En livraison'
    },
    {
      value: 'delivered',
      label: 'Livrée'
    }
  ]

  get filtersActive() {
    return Object.keys(this.filters).filter(i => {
      if (this.filters[i] && this.filters[i] !== '') {
        if ((typeof this.filters[i] === 'object' && this.filters[i].length > 0) || typeof this.filters[i] !== 'object') {
          if (i !== 'page' && i !== 'limit') {
            return i
          }
        }
      }
    }).length
  }

  async querySearchAsync(queryString: string, cb: any) {
    const { data } = await GiftCard
      .where({ name: { prefix: queryString } })
      .page(1)
      .per(999)
      .all()

    cb(data)
  }

  handleSelect(giftCard: GiftCard): void {
    this.filters.giftCardId = giftCard?.id
  }

  apply() {
    this.showFilters = false
    this.$emit('applyFilters', this.filters)
  }
}
